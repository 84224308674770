[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4a4c51;
  --login-bg2: #2e1b3f;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #d9a527;
  --saas-main-color: #693a0f;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #d9a527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #eb892e;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #ffdd8c;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #ffe7ac;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6c3500;
  --login-bg2: #201102;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.OpLt05Tw {
  background: var(--module-bg);
  padding: 32px 40px 40px;
  max-width: var(--max-width);
  margin: auto;
  border-radius: 2px;
}
.w9Q2Gh1Q {
  margin-left: 15px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.w9Q2Gh1Q:hover {
  color: var(--main-color);
}
.PSRqDMG3 {
  max-width: 1200px;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.PSRqDMG3 .FNKXr65C {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PSRqDMG3 .FNKXr65C .EpvF6O3L .WK_hc_ez {
  color: var(--white);
  width: 140px;
  height: 48px;
  margin-left: 15px;
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  border: none;
}
.PSRqDMG3 .YGBJOCza {
  position: relative;
  max-width: var(--max-width);
  width: 100%;
  height: 120px;
  margin: auto auto 16px;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .bew54EQy {
  background: var(--module-bg);
  text-align: center;
  padding: 0 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .jeIqKYob {
  width: 50%;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .fVMnlh7X {
  width: 1px;
  height: 50px;
  background: var(--main-bg-color);
  margin-left: 10px;
  margin-right: 20px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .DQ_7pYyj {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 {
  margin-right: 10px;
  font-family: var(--CommonMedium);
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f {
  display: flex;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .O5sldh0T {
  color: var(--text-color);
  text-align: start;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .R4mZP2PP {
  color: var(--text-color);
  text-align: start;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .yeBGuzBJ {
  max-width: 100px;
  margin-left: 10px;
  word-wrap: break-word;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA {
  width: 55px;
  height: 36px;
  background-color: var(--main-bg-color);
  border-radius: 9px;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA .__1r1E1eQF {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA .F98iE2zE {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .k03w1RLU {
  width: 106px;
  height: 64px;
  background-color: var(--main-bg-color);
  border-radius: 9px;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 98px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .k03w1RLU .__1r1E1eQF {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .k03w1RLU .F98iE2zE {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN {
  display: flex;
  align-items: center;
  padding-left: 6px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .Wsn9kqQx {
  flex-shrink: 0;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv {
  width: 440px;
  margin-left: 10px;
  margin-right: 12px;
  text-align: left;
  font-size: 12px;
  color: var(--text-color);
  line-height: 18px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv > p {
  display: flex;
  align-items: baseline;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv > p .jt0ttWgj {
  flex-shrink: 0;
  display: inline-block;
  width: 3px;
  height: 3px;
  background: var(--main-color);
  border-radius: 50%;
  margin-right: 4px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .__8l6nsZQO {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--CommonMedium);
  padding: 13px 20px;
  line-height: 14px;
  margin-right: 24px;
}
.PSRqDMG3 .YGBJOCza .__9mw54Pu_,
.PSRqDMG3 .YGBJOCza .sym5H_jA {
  background: var(--module-bg);
  text-align: center;
}
.PSRqDMG3 .YGBJOCza .__9mw54Pu_ .cDy4UHPN,
.PSRqDMG3 .YGBJOCza .sym5H_jA .cDy4UHPN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .sym5H_jA {
  cursor: pointer;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .UOdXgbkD {
  font-size: 36px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 12px;
  word-wrap: break-word;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .KqMCRNqv {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  position: relative;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .KqMCRNqv .__2x5E8IqQ {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
  font-weight: 700;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .mgwTZP4N:hover {
  text-decoration: underline;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 {
  background: var(--module-bg);
  padding: 32px 20px 0px;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz .SwdxmKpU {
  font-size: 14px;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz .NS_CRuXy {
  font-size: 22px;
  font-weight: bold;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .U9ScFGmN {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-color);
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .__7oIR0Uca {
  display: flex;
  justify-content: center;
  font-family: var(--CommonBold);
}
.PSRqDMG3 .rXofSjEh {
  position: relative;
  width: 100%;
  background: var(--tab-active-bg);
  margin-bottom: 40px;
}
.PSRqDMG3 .rXofSjEh .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.PSRqDMG3 .rXofSjEh .ant-btn:hover,
.PSRqDMG3 .rXofSjEh .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.PSRqDMG3 .rXofSjEh .ant-table-thead > tr > th {
  background-color: var(--table-heading-bg);
}
.PSRqDMG3 .rXofSjEh .Bm86seQ_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H {
  position: relative;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo {
  z-index: 999;
  position: absolute;
  left: 2px;
  top: 30px;
  width: 50px;
  height: 14px;
  border-radius: 100px;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo.Gs1YAN3z {
  background: var(--blue-level1);
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo.__6f9XlQvb {
  background: var(--purple);
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo > span {
  color: var(--white);
  font-size: 12px;
  transform: scale(0.7);
  font-family: var(--CommonBold);
  margin-bottom: 9px;
}
.PSRqDMG3 .rXofSjEh .FZ3BLEmf {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.PSRqDMG3 .rXofSjEh .FZ3BLEmf .IqUVIh9D {
  font-family: var(--CommonRegular);
  font-size: 16px;
  font-weight: 700;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR {
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .DKkZPq7z,
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ECZZQn47 {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  cursor: pointer;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .DKkZPq7z {
  border-bottom: 6px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ECZZQn47 {
  border-top: 6px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ant-table-row.ant-table-row-level-1 {
  background: var(--table-heading-bg);
}
.PSRqDMG3 .rXofSjEh .__70d2SkWw .SUGLVRjx {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.PSRqDMG3 .rXofSjEh .YPI5zd5k,
.PSRqDMG3 .rXofSjEh .YPI5zd5k > button {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.PSRqDMG3 .rXofSjEh .oKvYxg0F {
  margin-left: 15px;
}
.PSRqDMG3 .rXofSjEh .Oo_KGWeo {
  width: 100%;
  height: 110px;
  background: var(--module-bg);
  cursor: pointer;
  padding-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.PSRqDMG3 .rXofSjEh .x2yEFolO {
  background: var(--tab-active-bg);
  width: 100%;
  height: 110px;
  cursor: pointer;
  padding-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.PSRqDMG3 .rXofSjEh .aR0HcXwh {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  width: 100%;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.PSRqDMG3 .rXofSjEh .hEjr69_1 {
  font-size: 30px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 36px;
  margin-bottom: 22px;
}
.PSRqDMG3 .rXofSjEh .hEjr69_1 .CRb_rPf5 {
  display: flex;
  justify-content: center;
}
.PSRqDMG3 .rXofSjEh .hEjr69_1 .PjUSotb9 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.PSRqDMG3 .rXofSjEh .hEjr69_1 .PjUSotb9 .slEwstmQ {
  margin-left: 6px;
  font-size: 14px;
  opacity: 0.7;
  font-weight: 400;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--CommonRegular);
}
.PSRqDMG3 .rXofSjEh .zv1ZCfKe {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin-bottom: 24px;
}
.PSRqDMG3 .rXofSjEh .n4j4usRB {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
  margin-top: 30px;
}
.PSRqDMG3 .rXofSjEh .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.PSRqDMG3 .rXofSjEh .__2yWG67Yy {
  display: none;
}
.PSRqDMG3 ._6o_Brmp {
  width: 100%;
  padding: 35px 20px;
  background: var(--tab-active-bg);
}
.PSRqDMG3 ._6o_Brmp .YPI5zd5k {
  width: 100px;
}
.PSRqDMG3 ._6o_Brmp .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.PSRqDMG3 ._6o_Brmp .ant-table-thead > tr > th {
  background-color: var(--table-heading-bg);
}
.q5GFNjiQ {
  background-color: transparent;
  padding: 0;
}
.i6bYtAQH {
  margin: 24px auto 16px;
}
.i6bYtAQH .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.i6bYtAQH .__6ESccFUE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 10px;
}
.i6bYtAQH .VpLJlHGv {
  color: var(--tip-text-color);
}
.i6bYtAQH .Q_uBvtR_ {
  position: relative;
  width: 180px;
  height: 180px;
  background-color: var(--white);
  text-align: center;
  padding-top: 5px;
  overflow: hidden;
}
.i6bYtAQH .C1AJZZrB {
  border-right: 1px dashed var(--border-color);
}
.i6bYtAQH .C1AJZZrB .uUAE78iR {
  margin-bottom: 24px;
  width: 80px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 20px;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG {
  margin-bottom: 32px;
  display: flex;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .QfVUUN_g .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .QfVUUN_g .ant-select-arrow {
  color: var(--main-color);
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .gIR4Cwqc {
  margin-left: 60px;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .gIR4Cwqc .vUkZnhpL {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.i6bYtAQH .C1AJZZrB .FB2pEUmM {
  color: var(--white);
  font-size: 24px;
  font-weight: bold;
}
.i6bYtAQH .C1AJZZrB .Dk4o1M0_ {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 14px;
  max-width: 700px;
}
.i6bYtAQH .C1AJZZrB .Dk4o1M0_ span {
  color: var(--main-color);
  position: absolute;
  font-size: 14px;
  right: 12%;
  cursor: pointer;
}
.i6bYtAQH .r_H_ne1H {
  background: var(--main-bg-color);
  margin-top: 24px;
  padding: 16px;
  color: var(--text-color);
  line-height: 26px;
  font-family: var(--CommonRegular);
}
.i6bYtAQH .r_H_ne1H .S3WxqiNW {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.i6bYtAQH .ant-input {
  width: 90%;
}
.i6bYtAQH .anticon svg {
  display: inline-block;
}
.i6bYtAQH .ant-input[disabled] {
  cursor: pointer;
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 24px;
}
.UHFNJlUm p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.UHFNJlUm p .kQTf9Oj8 {
  font-family: var(--CommonRegular);
  color: var(--text-color);
}
.UHFNJlUm p ._auhOi9p {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.b58uZGr6 {
}
.b58uZGr6 .FZ3BLEmf {
  margin-bottom: 16px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 26px;
}
.b58uZGr6 .gQqB0rbf {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  cursor: pointer;
}
.b58uZGr6 .n4j4usRB {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.b58uZGr6 .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.m7auJwMm .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.m7auJwMm .ant-form-item-control-input-content {
  background: transparent;
}
.m7auJwMm .ant-modal-footer {
  border-top: none;
  margin-top: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
.m7auJwMm .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.m7auJwMm .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.m7auJwMm .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
}
.m7auJwMm .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.m7auJwMm .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
  margin-top: 20px;
}
.m7auJwMm .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.m7auJwMm .ant-modal-header > .ant-modal-title {
  color: var(--white);
}
.__5GL4w3km {
  background-color: transparent;
  padding: 0;
}
.__3NIzqWX_ {
  margin: 30px 0;
}
.__3NIzqWX_ p {
  margin-bottom: 5px;
}
.hv6YPGKc {
  margin-top: 20px;
  width: 120px;
  height: 36px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc {
  margin: 24px auto 16px;
}
.__7hO4U4wc .mrjqrBJA {
  display: flex;
  align-items: center;
  max-width: 700px;
  background: var(--alert-blue-bg);
  border: 1px solid var(--alert-blue-border);
  border-radius: 2px;
  padding: 9px 16px;
  box-sizing: border-box;
}
.__7hO4U4wc .mrjqrBJA span {
  margin-left: 10px;
}
.__7hO4U4wc .__6ESccFUE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}
.__7hO4U4wc .r_H_ne1H {
  margin-top: 32px;
}
.__7hO4U4wc .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.__7hO4U4wc .rMGX8Brc {
  background: var(--alert-blue-bg);
  border: 1px solid var(--alert-blue-border);
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 32px;
}
.__7hO4U4wc .rMGX8Brc .Y0P5nBB9 {
  background-color: var(--white);
  border-radius: 50%;
  border: 1px solid var(--blue1);
  color: var(--blue1);
  margin-right: 10;
}
.__7hO4U4wc .FfBRvNp6 {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .FfBRvNp6 :first-child {
  color: var(--text-color);
  margin-right: 8px;
}
.__7hO4U4wc .FfBRvNp6 :nth-child(2) {
  color: var(--green-text-color);
  cursor: pointer;
}
.__7hO4U4wc .__0TB4Yy8h {
  margin-top: 30px;
}
.__7hO4U4wc .__0TB4Yy8h .QfVUUN_g .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.__7hO4U4wc .__0TB4Yy8h .QfVUUN_g .ant-select-arrow {
  color: var(--main-color);
}
.__7hO4U4wc .__0TB4Yy8h .vUkZnhpL {
  border: 1px solid var(--main-color);
  color: var(--white);
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.__7hO4U4wc .ant-alert-info {
  background: var(--alert-blue-bg);
  border-radius: 2px;
  border: 1px solid var(--alert-blue-border);
}
.__7hO4U4wc .ant-alert-info .ant-alert-icon {
  color: var(--alert-blue-border);
}
.__7hO4U4wc .__8vtvsPvv {
  margin-top: 30px;
  height: 60px;
}
.__7hO4U4wc .__8vtvsPvv .XySva7Zz {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.__7hO4U4wc .__8vtvsPvv .XySva7Zz :nth-child(2) {
  color: var(--main-color);
  cursor: pointer;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY {
  display: flex;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .ant-input {
  border-radius: 0;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .KdeRkGEc {
  flex: 1;
  background-color: var(--main-bg-color);
  height: 40px;
  line-height: 40px;
  padding: 0px 11px;
  color: var(--white-op2);
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .KdeRkGEc .ytVikTxt {
  color: var(--disabled-text-color);
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .kJkQW5mt {
  width: 30px;
  height: 40px;
  cursor: pointer;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.__7hO4U4wc .__8vtvsPvv .TzYiMx7f {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .__8vtvsPvv .__94_aawbj {
  width: 100%;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.__7hO4U4wc .__8vtvsPvv .MZ__oXqe {
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .DKRx69HM {
  margin: 30px 0;
}
.__7hO4U4wc .DKRx69HM p {
  margin-bottom: 5px;
}
.__7hO4U4wc .Laex6fiE {
  max-width: 700px;
  margin-top: 30px;
  height: 60px;
}
.__7hO4U4wc .Laex6fiE .XySva7Zz {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.__7hO4U4wc .Laex6fiE .XySva7Zz :nth-child(2) {
  color: var(--green-text-color);
  cursor: pointer;
  text-decoration: underline;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 {
  display: flex;
  align-items: center;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM {
  background-color: var(--main-bg-color);
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM input::-webkit-outer-spin-button,
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .hsdyTU_w {
  font-size: 14px;
  color: var(--white);
  margin-top: 5px;
  margin-right: 5px;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .PKEI1qCI {
  color: var(--main-color);
}
.__7hO4U4wc .Laex6fiE .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .HqQf_8wy {
  margin-top: 50px;
  margin-bottom: 50px;
  height: 60px;
}
.__7hO4U4wc .HqQf_8wy .XySva7Zz {
  margin-bottom: 5px;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca {
  width: 100%;
  display: flex;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca .ylIv9fsz {
  width: 70%;
  margin-right: 20px;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca .DOImB3aG {
  height: 40px;
  color: var(--main-color);
  background-color: var(--main-bg-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .uqKEfmQv {
  background: var(--main-bg-color);
  margin-top: 24px;
  padding: 16px;
  color: var(--text-color);
  line-height: 26px;
  font-family: var(--CommonRegular);
}
.__7hO4U4wc .uqKEfmQv .S3WxqiNW {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.__7hO4U4wc .Dk4o1M0_ {
  max-width: 700px;
}
.__7hO4U4wc .hv6YPGKc {
  margin-top: 60px;
  width: 120px;
  height: 36px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .ciC_9NFs {
  margin-top: 50px;
}
.__7hO4U4wc .ciC_9NFs .S3WxqiNW {
  font-size: 20px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK {
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--text-color);
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK li {
  margin-bottom: 10px;
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK li span {
  font-weight: 700;
  color: var(--white);
}
.NyJcibVH {
  background-color: transparent;
  padding: 0;
}
.BkZTmjDj {
  margin: 24px auto 16px;
  padding: 38px 80px 93px;
}
.BkZTmjDj .S3WxqiNW {
  font-family: var(--CommonMedium);
  color: var(--white);
  font-size: 22px;
  margin-bottom: 32px;
}
.BkZTmjDj .KJGQrpR3 {
  width: 100%;
  margin-bottom: 50px;
}
.BkZTmjDj .KJGQrpR3 .S3WxqiNW {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 21px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD {
  width: 100%;
  height: 237px;
  padding: 32px 24px 36px;
  background: var(--grey-level2);
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh {
  width: 45%;
  color: var(--text-color);
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .__3f42ED3y {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI {
  height: 145px;
  background: var(--main-bg-color);
  padding: 28px 24px 27px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI p {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI .HPqqwmLM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI .HPqqwmLM p {
  display: flex;
  justify-content: right;
  margin-bottom: 0px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd {
  width: 45%;
  color: var(--text-color);
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .c2c8g59h {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ .__7psYnyxH {
  width: 100%;
  height: 59px;
  line-height: 59px;
  font-size: 24px;
  font-weight: bold;
  background: var(--main-bg-color);
  text-align: center;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ span {
  color: var(--main-color);
  position: absolute;
  font-size: 14px;
  right: 5%;
  cursor: pointer;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .yZ9DOJF5 {
  font-family: var(--CommonRegular);
  color: var(--tip-text-color);
}
.BkZTmjDj .tRmFLK97 {
  width: 100%;
  margin-bottom: 50px;
}
.BkZTmjDj .tRmFLK97 .S3WxqiNW {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 21px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD {
  width: 100%;
  padding: 32px 24px 36px;
  background: var(--grey-level2);
  color: var(--text-color);
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .vF9H2WqY {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 {
  background: var(--main-bg-color);
  padding: 24px;
  margin-bottom: 24px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 p,
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 li {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 p:last-child,
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 li:last-child {
  margin-bottom: 0px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .Qaq9216l {
  font-size: 14px;
  color: var(--white);
  margin-bottom: 15px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .__0G_d6Fwi {
  font-size: 14px;
  color: var(--white);
}
.BkZTmjDj .AFM7kJPT .ant-picker {
  width: 100%;
  height: 40px;
  background: var(--main-bg-color);
}
.BkZTmjDj .AFM7kJPT .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.BkZTmjDj .AFM7kJPT .ant-select-single .ant-select-selector .ant-select-selection-item,
.BkZTmjDj .AFM7kJPT .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
}
.BkZTmjDj .AFM7kJPT .ant-select {
  background: var(--main-bg-color);
}
.BkZTmjDj .AFM7kJPT .S3WxqiNW {
  font-family: var(--CommonMedium);
  color: var(--white);
  font-size: 16px;
  margin-bottom: 16px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO {
  margin-bottom: 26px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF {
  width: 48%;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF .QDKr4KIo {
  margin-bottom: 8px;
  font-family: var(--CommonRegular);
  color: var(--text-color);
  font-size: 14px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF .diRjYR59 {
  width: 100%;
  height: 40px;
  background-color: var(--main-bg-color);
  padding: 9px 16px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.BkZTmjDj .AFM7kJPT .__90havvHd {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}
.BkZTmjDj .AFM7kJPT .__90havvHd .g_scqoT9 {
  width: 33%;
  height: 72px;
  margin-right: 36px;
  white-space: nowrap;
}
.BkZTmjDj .AFM7kJPT .__90havvHd .DOImB3aG {
  color: var(--white);
  font-size: 14px;
}
.IP_PHncR {
  margin-top: 184px;
  position: relative;
}
.IP_PHncR .__5E7ruV74 {
  margin-bottom: 16px;
  width: 100%;
  height: 120px;
  background-color: var(--module-bg);
  padding: 25px 0;
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 .pHjI74jh {
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: bold;
  color: var(--white);
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 .tAZUak4Q {
  color: var(--text-color);
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 .lxbY0_nb {
  color: var(--main-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;
  cursor: pointer;
}
.IP_PHncR .__5E7ruV74 .nElxdjmG {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.IP_PHncR .cBcoNvaY {
  background-color: var(--module-bg);
  padding: 30px 24px 32px;
  min-height: 600px;
}
.IP_PHncR .cBcoNvaY .FZ3BLEmf {
  color: var(--white);
  font-family: var(--CommonBold);
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 24px;
}
.IP_PHncR .n4j4usRB {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.IP_PHncR .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.IP_PHncR .__4amxc_cZ {
  margin-bottom: 30px;
}
.FHCH96j7 p {
  color: var(--white);
  margin-bottom: 20px;
}
.wJQPs5m9 .ant-modal-body .btn-actions {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.wJQPs5m9 .ant-modal-body .btn-actions .ant-btn {
  height: 36px;
}
.anx0k1bP .ant-modal-body .ant-btn {
  width: 100%;
  height: 40px;
}
.UlVhAUT_ .i6bYtAQH .C1AJZZrB {
  border: none;
}
.UlVhAUT_ .i6bYtAQH .C1AJZZrB .Dk4o1M0_ span {
  right: 17%;
}
.UlVhAUT_ .i6bYtAQH .wxJ_Xxou {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px dashed var(--border-color);
}
.UlVhAUT_ .i6bYtAQH .S3WxqiNW {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}
.UlVhAUT_ .i6bYtAQH .__6ESccFUE {
  color: var(--white);
}
.UlVhAUT_ .i6bYtAQH .r_H_ne1H {
  margin-top: 32px;
}
.UlVhAUT_ .i6bYtAQH .r_H_ne1H .S3WxqiNW {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.UlVhAUT_ .i6bYtAQH .ant-btn {
  height: 40px;
  padding: 6px 30px;
}
.UlVhAUT_ .i6bYtAQH .ant-input {
  max-width: 700px;
  height: 44px;
}
.UlVhAUT_ .i6bYtAQH .ant-input:disabled {
  background: var(--main-bg-color);
}
.UlVhAUT_ .i6bYtAQH .ant-input:focus {
  border: none;
}
.UlVhAUT_ .i6bYtAQH .ant-input-suffix {
  margin-left: 30px;
}
.UlVhAUT_ .i6bYtAQH .ant-input-affix-wrapper:focus,
.UlVhAUT_ .i6bYtAQH .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.UlVhAUT_ .i6bYtAQH .IouC26Ah {
  margin-bottom: 32px;
  max-width: 700px;
}
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM {
  display: flex;
  align-items: center;
  max-width: 700px;
  height: 44px;
  background-color: var(--main-bg-color);
}
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM input::-webkit-outer-spin-button,
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.UlVhAUT_ .i6bYtAQH .__7Sg9nf_n {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.UlVhAUT_ .i6bYtAQH .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.UlVhAUT_ .__9xec_hkj {
  display: flex;
  margin: 30px 0;
}
.UlVhAUT_ .__9xec_hkj .Wsn9kqQx {
  flex-shrink: 0;
}
.UlVhAUT_ .__9xec_hkj > .X3CCpJs0 {
  margin-left: 10px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--CommonMedium);
}
.fwzhvOOi .__43_gFreS {
  width: 100%;
  display: flex;
  height: 26px;
  color: var(--white);
}
.fwzhvOOi .__43_gFreS > div {
  text-align: center;
}
.fwzhvOOi .__43_gFreS div.gY0CMZE1 {
  width: 30%;
  background: var(--btn-green-bg);
}
.fwzhvOOi .__43_gFreS div.MFknbmUy {
  background: var(--btn-red-bg);
  flex: 1;
}
.fwzhvOOi .__43_gFreS div.wgW7J8Xv {
  width: 30%;
  background: var(--main-color);
}
.fwzhvOOi .H3A6WN03 {
  display: flex;
  justify-content: center;
  background: var(--tab-active-bg);
  padding: 16px 0;
  font-size: 12px;
}
.fwzhvOOi .H3A6WN03 .ISq4Ako0,
.fwzhvOOi .H3A6WN03 .__7bD_bTA9 {
  width: 31%;
}
.fwzhvOOi .H3A6WN03 .ISq4Ako0 {
  padding-left: 15px;
}
.fwzhvOOi .H3A6WN03 .__4krWHRaW {
  flex: 1;
  padding-left: 10px;
}
.fwzhvOOi .H3A6WN03 .__7bD_bTA9 {
  padding-left: 10px;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 16px;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .hGqN65ze {
  color: var(--text-color);
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .wWiBjWPW {
  color: var(--white);
  text-align: right;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .I4yGOgVf {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.fwzhvOOi .BwfNutNa ._OTzCbp1 {
  margin-bottom: 20px;
}
.fwzhvOOi .BwfNutNa .__8iBlOHD7 {
  margin-top: 16px;
  margin-bottom: 26px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G {
  margin: 22px 0;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .__4mkjTw_n .ant-row {
  width: 130px;
  height: 36px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid var(--main-bg-color);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .__4mkjTw_n .ant-row:nth-child(odd) {
  background: var(--tab-active-bg);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI {
  width: calc(100% - 130px);
  color: var(--white);
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI::-webkit-scrollbar {
  height: 3px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row {
  flex-wrap: nowrap;
  height: 36px;
  align-items: center;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row > .ant-col {
  flex: 1;
  flex-basis: 102px;
  flex-shrink: 0;
  text-align: center;
  line-height: 36px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid var(--main-bg-color);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row:nth-child(odd) > .ant-col {
  background: var(--tab-active-bg);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row:last-child > .ant-col {
  border-left: unset;
}
.fwzhvOOi .aa_hP2Zf {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.fwzhvOOi .aa_hP2Zf .veLxoAqi {
  color: var(--white);
  margin: 0 10px;
}
.fwzhvOOi .aa_hP2Zf .veLxoAqi.dliDA3Ia {
  color: var(--text-color);
}
.fwzhvOOi .QpjkpfKV {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.fwzhvOOi .QpjkpfKV .__5qgO5mup {
  color: var(--white);
  margin-left: 16px;
}
.fwzhvOOi .QpjkpfKV .__5qgO5mup.dliDA3Ia {
  color: var(--text-color);
}
.__2AsZS76m span {
  color: var(--tip-text-color);
}
.ibDvPJ58 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ibDvPJ58 .sXNlKwB3 {
  margin-left: 6px;
  margin-right: 12px;
}
.ibDvPJ58 .igG9M1GJ {
  text-decoration: line-through;
  color: var(--remark-text-color);
}
.n4j4usRB {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.__3VSW43ng {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.__3VSW43ng .__6knq9Xz_ {
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
}
.__3VSW43ng .Xs08JxdH {
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
}
.kE4nme5q {
  color: var(--main-color);
  cursor: pointer;
}
.SUZzzfBx .ant-modal-footer {
  border-top: none;
  margin-top: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
.SUZzzfBx .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.SUZzzfBx .ant-modal-footer button:first-child:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: var(--white);
}
.SUZzzfBx .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
  color: var(--white) !important;
}
.SUZzzfBx .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.SUZzzfBx .ant-modal-footer .ant-btn {
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--main-color);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 4px;
  margin-top: 20px;
}
.SUZzzfBx .ant-modal-header > .ant-modal-title {
  color: var(--white);
}
.SUZzzfBx .ant-alert-info {
  background: var(--alert-blue-bg);
  border-radius: 2px;
  border: 1px solid var(--alert-blue-border);
}
.SUZzzfBx .ant-alert-info .ant-alert-icon {
  color: var(--alert-blue-border);
}
.Z__uxR78 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Z__uxR78 p {
  color: var(--white-op2);
  font-family: var(--CommonRegular);
  font-size: 14px;
  text-align: left;
  width: 100%;
}
.__4FmtoltF {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Zt1DyxA3 {
  max-height: 500px;
  overflow: auto;
  margin-top: 15px;
}
.Zt1DyxA3 .LdO1f3CA {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--btn-type1-bg);
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK {
  display: flex;
  align-items: center;
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK .KqMCRNqv {
  font-size: 16px;
  font-family: CommonMedium;
  margin-right: 10px;
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK .YFtOyF_E {
  font-size: 12px;
  padding: 0 6px;
  background-color: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  margin-right: 10px;
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK .TgXVn5Se {
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-text-color);
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK .cyuDB1yo {
  color: var(--white);
  font-size: 16px;
  font-family: var(--CommonBold);
}
.Zt1DyxA3 .LdO1f3CA .RnB7RhdK .K_mEzWdv {
  margin-left: 16px;
  margin-right: 8px;
  color: #057dde;
}
.Zt1DyxA3 .LdO1f3CA .JEkUUWnV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px 10px;
  cursor: default;
}
.Zt1DyxA3 .LdO1f3CA .G4lcgfS_ {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 16px 0;
  cursor: default;
}
.Zt1DyxA3 .LdO1f3CA .G4lcgfS_ .y17183vp {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.Zt1DyxA3 .LdO1f3CA .G4lcgfS_ .y17183vp .__6ESccFUE {
  color: var(--white);
  opacity: 0.7;
  font-family: var(--CommonRegular);
  margin-right: 8px;
}
.Zt1DyxA3 .LdO1f3CA .dliDA3Ia {
  color: var(--btn-disabled-text-color);
}
.Zt1DyxA3 .dliDA3Ia {
  opacity: 0.5;
}
.X1Ib3D4l {
  max-width: 1200px;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.X1Ib3D4l .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.X1Ib3D4l .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.X1Ib3D4l .ant-btn:hover,
.X1Ib3D4l .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
}
.X1Ib3D4l .ant-switch {
  background-color: var(--btn-grey1-bg);
  background-image: none;
}
.X1Ib3D4l .ant-switch-checked {
  background-color: var(--main-color);
}
.X1Ib3D4l .ant-switch-handle::before {
  background-color: var(--tab-active-bg);
}
.X1Ib3D4l .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.X1Ib3D4l .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.X1Ib3D4l .ant-table-thead > tr > th,
.X1Ib3D4l .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.X1Ib3D4l .ant-table-content {
  min-height: 235px;
}
.X1Ib3D4l .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.X1Ib3D4l .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.X1Ib3D4l .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.X1Ib3D4l .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.X1Ib3D4l .FNKXr65C {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.X1Ib3D4l .FNKXr65C .EpvF6O3L .WK_hc_ez {
  color: var(--white);
  height: 48px;
  font-size: 14px;
  text-align: center;
  border: none;
  font-family: var(--CommonMedium);
}
.X1Ib3D4l .d9y_L47W {
  height: 64px;
  background-color: var(--module-bg);
}
.X1Ib3D4l .__6knq9Xz_ {
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
}
.X1Ib3D4l .Xs08JxdH {
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
}
.X1Ib3D4l .DK1RnKzi {
  padding: 24px;
  margin-bottom: 16px;
  height: 500px;
  background-color: var(--tab-active-bg);
  overflow-y: auto;
}
.X1Ib3D4l .__8iA46Twb {
  padding-top: 120px;
  margin-bottom: 16px;
  height: 500px;
  background-color: var(--tab-active-bg);
  text-align: center;
}
.X1Ib3D4l .__8iA46Twb p {
  margin-top: 40px;
  color: var(--text-color);
}
.X1Ib3D4l .__8iA46Twb .__8jefqmkL {
  margin-top: 10px;
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
}
.X1Ib3D4l .CE_T1W_e {
  height: 120px;
  background-color: var(--tab-active-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}
.X1Ib3D4l .CE_T1W_e .Abkojx59 .cU6J4BgE {
  color: var(--white);
  font-family: var(--CommonMedium);
  font-size: 18px;
  margin-bottom: 16px;
}
.X1Ib3D4l .CE_T1W_e .Abkojx59 .B3mJBmsh {
  font-size: 16px;
  color: var(--white-op2);
}
.PKEI1qCI {
  color: var(--main-color);
}
.__1xv5avjw {
}
.__1xv5avjw .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.__1xv5avjw .L06grQFO {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 16px;
}
.__1xv5avjw .__6ESccFUE {
  color: var(--white-op2);
  margin-bottom: 8px;
}
.__1xv5avjw .mhQUfcGN {
  display: flex;
  margin: 16px 0px 24px;
}
.__1xv5avjw .mhQUfcGN .uXoZVhdn {
  margin-right: 60px;
}
.__1xv5avjw .mhQUfcGN .ant-select {
  background: transparent !important;
}
.__1xv5avjw .mhQUfcGN .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.__1xv5avjw .mhQUfcGN .ant-select-arrow {
  color: var(--main-color) !important;
}
.__1xv5avjw .u_68GTg3 {
  margin-bottom: 24px;
  width: 100%;
}
.__1xv5avjw .u_68GTg3 .ant-select {
  background: var(--main-bg-color);
}
.__1xv5avjw .u_68GTg3 .ant-select-selection-placeholder {
  font-size: 14px;
  font-family: var(--CommonRegular);
}
.__1xv5avjw .u_68GTg3 .ant-select-arrow {
  color: var(--white-op3) !important;
}
.__1xv5avjw .u_68GTg3 .ant-select-selection-item {
  font-size: 14px;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-bg-color);
  height: 40px;
  padding: 10px;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .Wbn1v6UE {
  color: var(--text-color);
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .__1_HG_Z3x {
  color: var(--main-color);
  font-family: var(--CommonMedium);
  cursor: pointer;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .MAWG2AMO {
  color: var(--disabled-text-color);
}
.__1xv5avjw .FZcaSrC9 {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 16px;
}
.__1xv5avjw .MZ__oXqe {
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__1xv5avjw .wnooxYI8 {
  display: flex;
  margin-bottom: 22px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 {
  margin-left: 16px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 .znrEyTov {
  font-family: var(--CommonMedium);
  margin-bottom: 8px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 .A6GazZV6 {
  width: 530px;
  color: var(--white-op2);
}
.__1xv5avjw .q_5Z6mX5 {
  margin-bottom: 40px;
}
.__1xv5avjw .q_5Z6mX5 .fJiPAmFZ {
  color: var(--white);
  font-family: var(--CommonMedium);
  margin-bottom: 7px;
}
.__1xv5avjw .q_5Z6mX5 .r_H_ne1H {
  color: var(--white-op2);
  margin-bottom: 5px;
}
.ZrHp0ib4 {
  color: var(--white);
}
.ZrHp0ib4 .__6ESccFUE {
  color: var(--white-op2);
  margin-bottom: 13px;
}
.ZrHp0ib4 .ant-select-selector {
  border-color: transparent !important;
  background-color: var(--module-bg) !important;
}
.MBKwYvjM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--module-bg);
  margin-bottom: 18px;
  padding: 15px 24px;
}
.MBKwYvjM .SxmsuYGA {
  height: 48px;
  width: 1px;
}
.MBKwYvjM .p6BPTWsN {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-op3);
  margin-bottom: 10px;
}
.MBKwYvjM .hsuiMDXh {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 3px;
}
.MBKwYvjM .RWghFbec {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op1);
  margin-bottom: 3px;
}
.hhsuyk5D {
  max-width: 200px !important;
}
.__5h7LKqoi {
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op1);
}
.dkg0cgU_ .ant-table-content {
  min-height: 0 !important;
}
.D_Gz861i {
  background: transparent;
}
.D_Gz861i .fvJTD5dL {
  background: var(--module-bg);
  margin: 24px auto 16px;
  padding: 50px 40px;
}
.D_Gz861i .fvJTD5dL .ant-input-group-wrapper {
  background: var(--main-bg-color);
}
.D_Gz861i .fvJTD5dL .ant-input {
  height: 44px;
}
.D_Gz861i .fvJTD5dL .ant-input-group-addon {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.D_Gz861i .fvJTD5dL .S3WxqiNW {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.D_Gz861i .fvJTD5dL .UCKF5sDe {
  font-size: 14px;
  line-height: 24px;
  color: var(--white-op2);
  margin-top: 14px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 .__89LxdE_j {
  width: 600px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 .__5neR9ksV {
  height: 44px;
  width: 171px;
  margin-left: 16px;
}
.D_Gz861i .fvJTD5dL .sMwObWv_ {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--CommonMedium);
  color: var(--white);
  margin-top: 43px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ {
  display: flex;
  margin-top: 17px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup {
  flex: 1;
  height: 177px;
  background: var(--main-bg-color);
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup:nth-child(n + 2) {
  margin-left: 29px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup .__0SWnAQKA {
  width: 49px;
  height: 49px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup .S3WxqiNW {
  font-size: 13px;
  line-height: 26px;
  color: var(--white-op2);
  margin: 16px 0 0;
  padding: 0 20px;
}
.EgZRowIg .__87zC6g77 {
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 24px;
}
.EgZRowIg .__0gHNva8k {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.EgZRowIg .__0gHNva8k .GXZL7PMr {
  color: var(--white);
  opacity: 0.6;
}
.EgZRowIg .__0gHNva8k .j_qAeUad {
  font-family: var(--CommonMedium);
}
.EgZRowIg .J3cjp_tE {
  display: flex;
  justify-content: end;
  margin-top: 32px;
}
.EgZRowIg .J3cjp_tE button {
  width: 108px;
  height: 36px;
}
.jIDVOTDJ {
  color: var(--white);
  font-family: var(--CommonBold);
  font-size: 20px;
  margin-top: 25px;
}
.Yn6_Xug5 {
  cursor: pointer;
  color: var(--main-color);
  display: flex;
  align-items: center;
}
.hc5flB3w {
  margin-left: 4px;
}
.mB6KdWHi {
  text-decoration: underline;
  cursor: pointer;
  color: var(--main-color);
}
.rgniYpfQ {
  overflow-y: scroll;
}
.rgniYpfQ .ZLOAFzgw {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.rgniYpfQ .ZLOAFzgw .__0SWnAQKA {
  border-radius: 50%;
  margin-right: 24px;
}
.rgniYpfQ .ZLOAFzgw .S3WxqiNW {
  font-size: 20px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.rgniYpfQ .P5y_ZdN5 {
  margin-bottom: 20px;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo .__2zCjpYlO {
  flex: 1;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo .__2zCjpYlO .PKEI1qCI {
  color: var(--main-color) !important;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo .__2zCjpYlO .dirBDaO2 {
  font-size: 12px;
  color: var(--white-op2);
  margin-bottom: 8px;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo .__2zCjpYlO .KqMCRNqv {
  font-size: 16px;
  color: var(--white);
  font-family: var(--CommonMedium);
  line-height: 27px;
}
.rgniYpfQ .P5y_ZdN5 .Nr0MQIQo .KWCTpo1Q {
  margin-left: 8px;
}
.kMXcDDiF {
  display: flex;
}
.kMXcDDiF .HHFodICn {
  height: 1035px;
  overflow-y: scroll;
  background-color: var(--module-bg);
  width: 845px;
  padding: 32px;
}
.kMXcDDiF .HHFodICn .Jft5f8O1 {
  margin-bottom: 10px;
  color: var(--white);
  font-size: 36px;
  font-family: var(--CommonBold);
  line-height: 54px;
}
.kMXcDDiF .HHFodICn .IlJBvRBc {
  font-size: 12px;
}
.kMXcDDiF .HHFodICn .IlJBvRBc .VTwZ0jtc {
  color: var(--white-op2);
  margin-right: 8px;
}
.kMXcDDiF .HHFodICn .IlJBvRBc .PIpwK_5T {
  color: var(--main-color);
  cursor: pointer;
  margin-right: 10px;
}
.kMXcDDiF .HHFodICn .gK1xIb6w {
  margin: 27px 0;
}
.kMXcDDiF .KWCTpo1Q {
  width: 327px;
  margin-left: 15px;
  flex: 1;
}
.kMXcDDiF .KWCTpo1Q .TNPT3AXw {
  color: var(--white);
  font-size: 22px;
  font-family: var(--CommonBold);
}
.kMXcDDiF .KWCTpo1Q .R1ZG1ViD {
  height: 327px;
  background-color: var(--module-bg);
  padding: 24px 16px;
}
.kMXcDDiF .KWCTpo1Q .R1ZG1ViD .HovIXnJf {
  margin-bottom: 20px;
}
.kMXcDDiF .KWCTpo1Q .R1ZG1ViD .GjpTRP8E {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.kMXcDDiF .KWCTpo1Q .R1ZG1ViD .GjpTRP8E .QDKr4KIo {
  font-size: 12px;
  color: var(--white-op2);
}
.kMXcDDiF .KWCTpo1Q .R1ZG1ViD .GjpTRP8E .wWiBjWPW {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.kMXcDDiF .KWCTpo1Q .O9Gxb3XB {
  padding: 24px 16px;
  margin: 16px 0px;
  max-height: 466px;
  overflow: hidden;
  background-color: var(--module-bg);
}
.kMXcDDiF .KWCTpo1Q .O9Gxb3XB .jQFRG76x {
  cursor: pointer;
  margin-bottom: 20px;
}
.kMXcDDiF .KWCTpo1Q .O9Gxb3XB .jQFRG76x .AZdmmV4P {
  width: 100%;
  color: var(--white-op3);
  line-height: 20px;
  font-family: var(--CommonMedium);
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kMXcDDiF .KWCTpo1Q .O9Gxb3XB .jQFRG76x .Bd6Ozuse {
  font-size: 12px;
  color: var(--white-op2);
}
.kMXcDDiF .KWCTpo1Q .YI3wNhht {
  height: 207px;
  background-color: var(--module-bg);
}
.__2_YOKZzc {
  display: grid;
  place-items: center;
  height: 100vh;
}
.bgJTUyB1 {
  cursor: pointer;
  color: var(--white-op1);
}
.__1GGed0qn {
  color: var(--white-op2);
  margin-bottom: 27px;
  font-size: 14px;
  line-height: 22px;
}
.gJpkz298 {
  color: var(--white-op5);
}
.OlracA7l {
  color: var(--white-op2);
}
.OSduzb0i {
  color: var(--white);
}
.Omm1iQLN {
  background-color: var(--white);
  border-radius: 50%;
  color: var(--red-text-color);
  margin-right: 10;
}

